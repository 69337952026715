import axios from "axios";
import { toast } from "react-toastify";
import { decodeToken, getToken } from "../utils";
import { AuthUtils } from "../utils/auth.utils";
import { HTTP_STATUS_CODES } from "../constants/http.constants";
import { ERROR_MESSAGES } from "../constants/errors.constants";
const authUtils = new AuthUtils();

export let API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const $http = axios.create({
  baseURL: API_BASE_URL,
});

const showErrorMessage = (message) => {
  toast(message, { type: "error" });
};

export const requestInterceptior = (request) => {
  const token = authUtils.getToken();

  if (token) {
    request.headers.authorization = token;
  }

  return request;
};

export const responseInterceptior = $http.interceptors.request.use(requestInterceptior);

$http.interceptors.response.use(undefined, (config) => {

  if (config?.response?.status) {
    switch (config?.response?.status) {

      case HTTP_STATUS_CODES.unauthorised:
        showErrorMessage(ERROR_MESSAGES.unauthorsied);
        break;

      default:
        showErrorMessage(ERROR_MESSAGES.common);
        break;
    }
  }

  return config;
});
