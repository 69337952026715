import { $http } from "../api/http";

export const getWebsiteTemplate = (userId, docId, config = {}) =>
  $http.get(`/users/${userId}/website/${docId}`, config);

export const updateWebsiteTemplate = (userId, docId, payload, config = {}) => {
  return $http.put(`/users/${userId}/website/${docId}`, payload, config);
};

export const createWebsiteTemplate = (userId, payload, config = {}) => {
  return $http.post(`/users/${userId}/website`, payload, config);
};
