import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function SaveTemplateModal({ open, setOpen, submitHandler }) {
  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = React.useState({
    title: "",
  });

  function updateFormData(event) {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            submitHandler(formData);
            handleClose();
          },
        }}
      >
        <DialogTitle>Enter the name of website</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="title"
            name="title"
            label="Name"
            type="text"
            fullWidth
            onInput={(event) => updateFormData(event)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
