export const getLocalStorageValue = (key = "") => {
  if (!key) {
    throw new Error("key is required in getLocalStorageValue function");
  }

  return localStorage.getItem(key);
};

export const setLocalStorageValue = (key, value) => {
  if (!key) {
    throw new Error("key is required in setLocalStorageValue function");
  }

  return localStorage.setItem(key, value);
};

export function isDateGreaterThan(date1, date2) {
  return date1.getTime() > date2.getTime();
}
