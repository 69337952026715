import React from 'react';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function NotificationToaster() {
  return (
    <div className='notifcation-toaster-wrapper'>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className="notifcation-toaster"
        pauseOnFocusLoss
        draggable
        theme="light" />
    </div>
  );
}