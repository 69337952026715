import React, { useRef, useEffect, useState, useMemo } from "react";
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import "./GrapesEditor.css";
import pluginBlockBasic from "grapesjs-blocks-basic";
import axios from "axios";
import { API_BASE_URL } from "../../api/http";
import pluginCodeEditor from "grapesjs-component-code-editor";
import pluginPostCss from "grapesjs-parser-postcss";
import { Spinner } from "../common/Spinner/Spinner";
import Button from "@mui/material/Button";
import {
  createWebsiteTemplate,
  getWebsiteTemplate,
  updateWebsiteTemplate,
} from "../../services/builder";
import { AuthUtils } from "../../utils/auth.utils";
import { HtmlUtils } from "../../utils/html.utils";
import { toast } from "react-toastify";
import SaveTemplateModal from "../SaveTemplateModal";
import { isDateGreaterThan } from "../../utils";

const authUtils = new AuthUtils();
const htmlUtils = new HtmlUtils();

var isSubdomain = function (url) {
  url = url || "https://websitetoast.com";
  var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);

  return !!url.match(regex);
};

export const GrapesEditor = () => {
  const editor = useRef(null);
  const [isDomain, setIsDomain] = useState(false);
  const [templateHtml, setTemplateHtml] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [website, setWebsite] = useState(null);
  const [showTitleDialog, setShowTitleDialog] = React.useState(false);

  function getQueryParams() {
    let query = { token: "", docId: "" };
    if (window.location?.search) {
      const loginUrlSplitted = window.location?.search.split("?");

      if (loginUrlSplitted.length > 0) {
        query.token = loginUrlSplitted?.[1]?.replace("token=", "");
        query.docId = loginUrlSplitted?.[2]
          ? loginUrlSplitted?.[2]?.replace("doc_id=", "")
          : "";
        authUtils.setToken(query.token.replace("/website/", ""));
      }
    }

    return query;
  }

  function showLoginMessageToUser(params) {
    const token = authUtils.getToken();
    if (!token) {
      toast.error(
        "You must be signed in user to save. Trying navigating from the link again"
      );
    }
  }

  const isWebsitePathExists = useMemo(() => website?.website_path, [website]);

  useEffect(() => {
    const { docId } = getQueryParams();
    const windowHost = window.location.host;
    // isSubdomain(windowHost)
    if (
      docId &&
      (windowHost.includes("websitetoast.com") ||
        windowHost.includes("localhost:3000"))
    ) {
      setIsDomain(true);
      getTemplate();
    } else {
      renderGrapejs();
      showLoginMessageToUser();
    }
  }, []);

  function createOrUpdateTemplate() {
    const idInputElement = document.getElementById("idInput");
    const domainVal = idInputElement?.value;

    const { docId, token } = getQueryParams();

    if (docId) {
      updateTemplate(
        domainVal,
        editor.current.getHtml(),
        editor.current.getCss(),
        editor.current.getJs()
      );
    } else if (token) {
      const decodedToken = authUtils.decodeToken(token) ?? {};

      if (
        decodedToken &&
        isDateGreaterThan(new Date(decodedToken.exp * 1000), new Date())
      ) {
        setShowTitleDialog(true);
      }
    }
  }

  function renderGrapejs() {
    if (editor.current) {
      return;
    }
    editor.current = grapesjs.init({
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: "#gjs",
      // Get the content for the canvas directly from the element
      // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
      fromElement: true,
      // Size of the editor
      width: "auto",
      // Disable the storage manager for the moment
      storageManager: false,
      plugins: [pluginBlockBasic, pluginCodeEditor, pluginPostCss],
      allowScripts: 1,
      canvas: {
        scripts: ["https://code.jquery.com/jquery-3.6.0.min.js"],
      },
    });
    window.editor = editor.current;
    const pn = editor.current.Panels;
    const bm = editor.current.BlockManager;
    var cmdm = editor.current.Commands;
    var modal = editor.current.Modal;
    bm.remove("column1");
    bm.remove("column2");
    bm.remove("column3");
    bm.remove("column3-7");
    bm.remove("link");

    // Add default video for video block
    bm.get("video").set({
      content: {
        type: "video",
        src: "/video/video2.mp4",
        style: {
          height: "350px",
          width: "615px",
        },
      },
    });

    //Add default address for Map block
    bm.get("map").set({
      content: {
        type: "map",
        src: "https://maps.google.com/maps?&q=Australia&z=1&t=q&output=embed",
        style: {
          height: "350px",
        },
      },
    });

    // Add new button for code editor
    pn.addButton("options", [
      {
        id: "open-code",
        className: "fa fa-file-code-o",
        command: "open-code",
        togglable: false,
      },
    ]);

    const pfx = editor.current.getConfig().stylePrefix;
    cmdm.add("domain_modal", function () {
      const container = document.createElement("div");

      const inputHtml = `
    <div class="form-group">
      <label>Domain Name </label>
      <input type="text" class="form-control" placeholder="Enter Subdomain" name="address" id="idInput">
    </div>
    <br />`;

      const btnEdit = document.createElement("button");
      btnEdit.innerHTML = "Submit";
      btnEdit.className = pfx + "btn-prim " + pfx + "btn-import";
      btnEdit.onclick = function () {
        try {
          createOrUpdateTemplate();

          modal.close();
        } catch (error) {
          console.log("error :>> ", error);
        }
      };

      modal.setTitle("Domain Panel");
      container.innerHTML = inputHtml;
      container.appendChild(btnEdit);
      modal.setContent(container);
      modal.open();
    });

    cmdm.add("save-template", function () {
      createOrUpdateTemplate();
    });

    editor.current.on("load", () => {
      editor.current.getConfig().showDevices = 0;

      //New Devices buttons
      editor.current.Panels.addPanel({
        id: "devices",
        buttons: [
          {
            id: "set-device-desktop",
            command: function (e) {
              return e.setDevice("Desktop");
            },
            className: "fa fa-desktop",
            active: 1,
          },
          {
            id: "set-device-tablet",
            command: function (e) {
              return e.setDevice("Tablet");
            },
            className: "fa fa-tablet",
          },
          {
            id: "set-device-mobile",
            command: function (e) {
              return e.setDevice("Mobile portrait");
            },
            className: "fa fa-mobile",
          },
          {
            id: "vercel",
            command: (e) => e.runCommand("domain_modal"),
            className: "fa fa-rocket",
          },
          {
            id: "Save",
            command: (e) => e.runCommand("save-template"),
            className: "fa fa-floppy-o",
          },
        ],
      });

      // Active block button on load
      var openBlocksBtn = pn.getButton("views", "open-blocks");
      openBlocksBtn && openBlocksBtn.set("active", 1);

      // Active Stylemenger on selection on element
      editor.current.on("component:selected", () => {
        const openSmBtn = pn.getButton("views", "open-sm");
        openSmBtn.set("active", 1);
      });
    });
  }

  function setWebsiteConfigInEditor(websiteHtml) {
    const websiteDomEl = document.querySelector("#website");
    if (websiteDomEl) {
      window.editor.setComponents(websiteHtml);
    }
  }

  async function getTemplate() {
    renderGrapejs();
    setIsLoading(true);
    const { token, docId } = getQueryParams();
    const decodedToken = authUtils.decodeToken(token) ?? {};

    getWebsiteTemplate(decodedToken.user_id, docId)
      .then((response) => {
        if (response.data && response.data.website) {
          setWebsiteConfigInEditor(response.data.website.html);
          setWebsite(response.data.website);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.error ?? error.message;
        alert(errorMessage);
        setIsDomain(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function updateTemplate(domain, html, css, js, title = "") {
    const { token, docId } = getQueryParams();
    const decodedToken = authUtils.decodeToken(token) ?? {};
    const newTemplate = htmlUtils.computeValidHtml({ css, html, js });

    toast.promise(
      updateWebsiteTemplate(decodedToken.user_id, docId, {
        content: newTemplate,
        // title: title ?? website.title,
      }),
      {
        pending: "Website is updating",
        success: "Website is updated 👌",
        error: "Something went wrong 🤯",
      }
    );
  }

  // function onSubmitHandler({ title }) {
  //   if (!isWebsitePathExists) {
  //     updateTemplate(
  //       "",
  //       editor.current.getHtml(),
  //       editor.current.getCss(),
  //       editor.current.getJs(),
  //       title
  //     );
  //   } else {
  //     createTemplate({ title });
  //   }
  // }

  async function createTemplate({ title }) {
    const html = editor.current.getHtml();
    const css = editor.current.getCss();
    const js = editor.current.getJs();

    const { token } = getQueryParams();
    const decodedToken = authUtils.decodeToken(token) ?? {};
    const newTemplate = htmlUtils.computeValidHtml({ css, html, js });

    toast.promise(
      createWebsiteTemplate(decodedToken.user_id, {
        content: newTemplate,
        title: title,
      }),
      {
        pending: "Website is being created",
        success: "Website is created 👌",
        error: "Something went wrong 🤯",
      }
    );
  }

  function createMarkup() {
    return { __html: templateHtml };
  }
  return (
    <div id="webtoast-editor">
      <Spinner isLoading={isLoading} />
      <link
        href="https://unpkg.com/grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css"
        rel="stylesheet"
      />
      <div id="gjs"></div>
      <SaveTemplateModal
        open={showTitleDialog}
        setOpen={setShowTitleDialog}
        submitHandler={createTemplate}
      />
      <div id="website" style={{ display: "none" }} />
    </div>
  );
};
