export class HtmlUtils {

  /**
  |--------------------------------------------------
  | This is sample html builder template
  |--------------------------------------------------
  */
  sampleHtml = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>$CSS</style>
            <title>$TITLE</title>
        </head>
        <body>
            $HTML
            <script>$JS</script>
        </body>
        </html>
`;

  computeValidHtml({ html, js, css, title }) {

    let newHtml = this.sampleHtml.replace(`$CSS`, css ?? "")
    newHtml = newHtml.replace(`$HTML`, html ?? "")
    newHtml = newHtml.replace(`$JS`, js ?? "")
    newHtml = newHtml.replace(`$TITLE`, title ?? "")

    return newHtml

  }
}
