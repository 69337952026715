import './App.css';
import { GrapesEditor } from './component/GrapesEditor/GrapesEditor';
import { NotificationToaster } from './component/common/NotificationToaster/NotificationToaster';

const App = () => {
  return (
    <div>
      <NotificationToaster />
      <GrapesEditor />
    </div>
  );
};

export default App;
