import React from "react";
import { Circles } from "react-loader-spinner";
import "./Spinner.css";

export const Spinner = ({ isLoading }) => {
  return (
    <div className={isLoading && "spinner-overlay"}>
      <Circles
        height="80"
        width="80"
        color="#FD6570"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={isLoading}
      />
    </div>
  );
};
