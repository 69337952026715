import { getLocalStorageValue, setLocalStorageValue } from "./index"
import { LOCALSTORAGE_DATA_KEYS } from "../constants/auth.constants"

export class AuthUtils {

  getToken() {
    return getLocalStorageValue(LOCALSTORAGE_DATA_KEYS.token)
  }

  removeToken() {
    return localStorage.removeItem(LOCALSTORAGE_DATA_KEYS.token)
  }

  setToken(value) {
    return setLocalStorageValue(LOCALSTORAGE_DATA_KEYS.token, value)
  }

  decodeToken(token) {
    if (!token) return "";

    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch {
      return null;
    }
  }

}